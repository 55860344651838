import React from 'react';
import commentBox from 'commentbox.io';
import { Link } from 'gatsby';
import Author from './Author';
import Content from './Content';
import Meta from './Meta';
import Tags from './Tags';
import styles from './Post.module.scss';

class Post extends React.Component {
  componentDidMount() {
    this.removeCommentBox = process.env.NODE_ENV === 'development' ? null : commentBox('5721598178885632-proj');
  }

  componentWillUnmount() {
    if (this.removeCommentBox) this.removeCommentBox();
  }

  render() {
    const {
      tags,
      title,
      date
    } = this.props.post.frontmatter;

    const { html } = this.props.post;
    const { tagSlugs } = this.props.post.fields;

    return (
      <div className={styles['post']}>
      <Link className={styles['post__home-button']} to="/">All Articles</Link>

      <div className={styles['post__content']}>
        <Content body={html} title={title} />
      </div>

      <div className={styles['post__footer']}>
        <Meta date={date} />
        <Tags tags={tags} tagSlugs={tagSlugs} />
        <Author author={this.props.author} />
        <div className="commentbox" />
      </div>

    </div>
    );
  }
}

export default Post;
