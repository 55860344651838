import React from 'react';
import { graphql } from 'gatsby';
// import { setISOYear } from 'date-fns';

import Layout from '../components/Layout';
import Post from '../components/Post';

const PostTemplate = ({ data }) => {
  const {
    title: siteTitle,
    subtitle: siteSubtitle,
    author
  } = data.site.siteMetadata;

  const {
    title: postTitle,
    description: postDescription
  } = data.markdownRemark.frontmatter;

  const metaDescription = postDescription !== null ? postDescription : siteSubtitle;

  const socialConfig = {
    twitterHandle: '@nigelballtech',
    config: {
      url: 'https://nigelball.tech/my-slug',
      title: 'My Blog'
    }
  };

  return (
    <Layout title={`${postTitle} - ${siteTitle}`} description={metaDescription}>
      <Post socialConfig={socialConfig} post={data.markdownRemark} author={author} />
    </Layout>
  );
};

export const query = graphql`
  query PostBySlug($slug: String!) {
    site {
      siteMetadata {
        author {
          name
          contacts {
            twitter
          }
        }
        subtitle
        title
        siteUrl
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      fields {
        slug
        tagSlugs
      }
      frontmatter {
        date
        description
        tags
        title
      }
    }
  }
`;

export default PostTemplate;
