import React from 'react';
import Helmet from 'react-helmet';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import styles from './Layout.module.scss';

library.add(fab);

const Layout = ({
  children, title, description, siteUrl
}) => (
  <div className={styles.layout}>
    <Helmet defaultTitle={title} titleTemplate={`%s | ${title}`}>
      <html lang="en" />
      <link rel="canonical" href={`${siteUrl}`} />
      <meta name="description" content={description} />
      <meta
        name="viewport"
        content="width=device-width,initial-scale=1,shrink-to-fit=no,viewport-fit=cover"
      />
    </Helmet>
    {children}
  </div>
);

export default Layout;
